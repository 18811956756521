//React
import {useEffect, useState} from "react";
//Redux
import {useDispatch, useSelector} from "react-redux";
import {setParks, setStations, setStationsLoading} from "../../redux/slices/mapSlice";
import {setLoading, setMapLoading} from "../../redux/slices/globalSlice";
import {setStationFilters} from "../../redux/slices/searchSlice";
import {setPermissions, setScanner, setFcmToken} from "../../redux/slices/deviceSlice";
//Components
import MapComponent from "../Map";
import TGIcon from "./TGIcon";
//Helpers
import {
  getStations,
  getLocation,
  createStationMarkers,
  createCenterMarker,
  editUser,
  isUserLoggedIn,
  redirectUrl,
  getParks,
  createParksMarkers,
  getVehicles,
  getPaymentMethods,
  getWalletBalance, env, getStationDetail,
} from "../../helpers";
import {t} from 'i18next'
import {useHistory} from "react-router";
import {Alert} from "../../helpers/alert";
import {Capacitor} from "@capacitor/core";
import {Geolocation} from "@capacitor/geolocation";
import {DeviceOrientation} from "@awesome-cordova-plugins/device-orientation";
import {useAppRate} from "../../hooks";
import {PushNotifications} from '@capacitor/push-notifications';
import {MapMarkerIcons} from "../../assets/mapMarkerIcons";
import {setRegisterPagesNeverAppear} from "../../redux/slices/userSlice";
import {setChangeBuyStationModal, setGlobalPushModalName} from "../../redux/slices/modalSlice";

const TGMap = ({map, setMap}) => {

    const history = useHistory()
    const dispatch = useDispatch()

    const {invoices} = useSelector(state => state.user)
    const {scanner, fcmToken} = useSelector(state => state.device);
    const {stationFilters} = useSelector(state => state.search)
    const {searchModal, stationDetailModal} = useSelector(state => state.modal)
    const {userPosition, stations, mapFilter,parks,stationLoading} = useSelector(state => state.map);
    const {mapLoading, qrRedirection: {pathname: QRPath, params: QRParams}} = useSelector(state => state.global);
    const isMembershipActive = env("IS_MEMBERSHIP_ACTIVE") === "true";
    const isWalletActive = env("IS_WALLET_ACTIVE") === "true";
    const [heading, setHeading] = useState(0);
    const [currentZoom, setCurrentZoom] = useState(12);
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [isDefaultLocation, setIsDefaultLocation] = useState(true)
    const [centerMarker, setCenterMarker] = useState({current: null, previous: null});

    useAppRate(invoices.length > 0);

    useEffect(() => {
        dispatch(setRegisterPagesNeverAppear(true))
        dispatch(setMapLoading(true))
        handleLocation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (Capacitor.isNativePlatform()) {


            PushNotifications.register()
            PushNotifications.addListener("registration", async (token) => {
                if (token.value !== fcmToken && isUserLoggedIn()) {
                    await editUser({notification_user_id: token.value}).then(() => {
                        dispatch(setFcmToken(token.value))
                    })
                    // Method remove badge number
                    PushNotifications.removeAllDeliveredNotifications()
                }
            })

            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed',
              (res) => {
                  if (res.notification.data.url) redirectUrl(res.notification.data.url)
                  const modalName = res.notification.data.modal_name
                  if (modalName) dispatch(setGlobalPushModalName(modalName))
              }
            );

            return () => PushNotifications.removeAllListeners()
        }
    }, [])

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (QRPath && QRParams) {
                dispatch(setLoading(false))
                history.push(QRPath, {...QRParams})
            }
        }, 1000)
        return () => clearTimeout(timeout)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [QRPath])

    useEffect(() => {
        if (!scanner.status && scanner.goWhenDisable !== "") {
            history.push(scanner.goWhenDisable);
            return () => dispatch(setScanner({status: false, goWhenDisable: ""}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scanner]);

    useEffect(() => {
        Capacitor.isNativePlatform() && watchHeading()
    }, [])

    const watchHeading = async () => {
        const heading = DeviceOrientation.watchHeading()
            .subscribe(({
                next: (data) => {
                    setHeading(data.magneticHeading.toFixed())
                },
                error: (e) => console.error(e)
            }));
        return () => heading.unsubscribe()
    }

    useEffect(() => {
        isMapLoaded && handleCenterMarker()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [heading])

    useEffect(() => {
        isMapLoaded && setMapByUserPosition(userPosition)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMapLoaded, userPosition])

    useEffect(() => {
        if (!isMapLoaded || searchModal.status || stationDetailModal.status) return;
        getStationList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchModal])

    useEffect(() => {
        if (!isMapLoaded) return;
        getStationList()
        isMembershipActive && getParkList()
        isDefaultLocation && map.map.setZoom(10)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPosition, isMapLoaded]);

    useEffect(() => {
        if (!isMapLoaded) return;
        getStationList();
        if (mapFilter === "park") {
            dispatch(setStationFilters({type: "ac", status: false}))
            dispatch(setStationFilters({type: "dc", status: false}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapFilter, stationFilters]);

    useEffect(() => {
        if (isMapLoaded) {
            initFunctions();
            const timeout = setTimeout(() => {
                dispatch(setMapLoading(false))
            }, 500)
            return () => clearTimeout(timeout)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMapLoaded])

    useEffect(() => {
        if (map.maps && map.map) {
            createStationMarkers(map.maps, map.map, stations)
            createParksMarkers(map.maps, map.map, parks)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stations,parks])


    useEffect(() => {
        centerMarker.previous && centerMarker.previous.setMap(null)
    }, [centerMarker])

    const getStationList = async () => {
        dispatch(setStationsLoading(true))
        const {lat, lng} = userPosition
        getStations(lat, lng).then(stations => dispatch(setStations(stations)));
        dispatch(setStationsLoading(false))
    }

    const getParkList = async () => {
        const {lat, lng} = userPosition
        // getParks(lat, lng).then(parks => dispatch(setParks(parks)));
    }

    const handleLocation = async () => {
        if (Capacitor.isNativePlatform()) {
            let locationPermission
            const res =  await Geolocation.checkPermissions()
            locationPermission = res

            if (res.location !== "granted") {
                locationPermission = await Geolocation.requestPermissions("location");
            }
            if (locationPermission.location === "granted") {
                dispatch(setPermissions({type: "location", status: true}));
                getLocation()
                setIsDefaultLocation(false);
            } else {
                dispatch(setPermissions({type: "location", status: false}));
                Alert.error(t("location-can't-be-found"))
            }
        } else {
            navigator.geolocation.getCurrentPosition((data) => {
                if (data.coords.latitude !== undefined) {
                    dispatch(setPermissions({type: "location", status: true}))
                    getLocation()
                    setIsDefaultLocation(false);
                }
                dispatch(setPermissions({type: "location", status: false}))
            })
        }
    }

    const setMapByUserPosition = (position) => {
        const lat = parseFloat(position.lat);
        const lng = parseFloat(position.lng);
        if (lat && lng) {
            handleCenterMarker()
            map.map.setCenter({lat, lng})
            map.map.setZoom(currentZoom)
        }
    }

    const handleCenterMarker = () => {
        if (!centerMarker.current) {
            const marker = createCenterMarker(map?.maps, map?.map, userPosition, heading);
            setCenterMarker({current: marker, previous: null});
        } else {
            centerMarker.current.setPosition(userPosition);
            updateMarkerIcon(centerMarker.current, heading);
        }
    }

    const updateMarkerIcon = (marker, heading) => {
        const newIcon = `data:image/svg+xml;base64,${window.btoa(MapMarkerIcons.centerMarker(Number(heading)))}`;
        marker.setIcon(newIcon);
    }

    const initFunctions = () => {
        if (isUserLoggedIn()){
            getVehicles();
            getPaymentMethods();
            isWalletActive && getWalletBalance();
        }
    }


    return (
        <>
            <TGIcon
                name="map-loading-screen"
                cssClass={`MapLoadingBackground  ${
                    mapLoading.status === true ? "active" : ""
                }`}
            />
            <MapComponent
                setMap={setMap}
                setMapLoaded={setIsMapLoaded}
                setCurrentZoom={setCurrentZoom}
            />
        </>
    );
};

export default TGMap;
